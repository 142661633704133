<template>
	<div
		class="wrap__content"
		v-bind:class="{ 'wrap__content--preloader': !applications || !roleList || !_getRegionList }"
	>
		<v-preloader
			v-if="!applications || !roleList || !_getRegionList"
			:message="errorMessage"
		></v-preloader>

		<div class="container" v-if="applications && roleList && _getRegionList">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['UserManagementMain', 'UserManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">{{ $t('titles.edit_user') }}</div>
										<div class="content__title--subtext">
											{{ applications.first_name }} {{ applications.last_name }}
											{{ applications.middle_name }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" @submit.prevent="onSubmit">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.role') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.role_id
																? 'input--error'
																: '',
														]"
													>
														<multiselect
															class="select__status"
															v-model="roleModel"
															:options="roleList"
															label="description"
															:placeholder="$t('labels.select_role')"
															><!--name="role"-->
															<template slot="option" slot-scope="{ option }">{{
																option.description
															}}</template>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.description
															}}</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.role_id"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.role_id"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										class="form__line"
										v-if="
											_getRegionList &&
											roleModel.accesses.find((element) => {
												return element == 'role_region'
											}) == 'role_region'
										"
									>
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.regions') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.region_id
																? 'input--error'
																: '',
														]"
													>
														<multiselect
															class="select__status"
															v-model="regionModel"
															:options="_getRegionList"
															:placeholder="$t('labels.select_region')"
															:multiple="true"
															:close-on-select="false"
															:clear-on-select="false"
															:preserve-search="true"
															track-by="id"
															label="name"
															required=""
														>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.name
															}}</template>
															<template slot="option" slot-scope="{ option }">
																<div class="select__check">
																	<div class="select__check--name">
																		{{ option.name }}
																	</div>
																</div>
															</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.region_id"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.region_id"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.user_name') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.first_name
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="first_name"
															v-model="applications.first_name"
															:placeholder="$t('headers.user_name')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.first_name"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.first_name"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.user_surname') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.last_name
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="last_name"
															v-model="applications.last_name"
															:placeholder="$t('headers.user_surname')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.last_name"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.last_name"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.user_patronymic') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.middle_name
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															name="middle_name"
															v-model="applications.middle_name"
															:placeholder="$t('headers.user_patronymic')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.middle_name"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.middle_name"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<!--
                  <div class="form__line">
                    <div class="form__line--left">
                      <div class="form__line--title">
                        Название департамента/управления/отдела
                      </div>
                    </div>
                    <div class="form__line--right">
                      <div class="row">
                        <div class="col-xl-5">
                          <div class="form__line--input">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  -->

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.user_role') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.job
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															name="job"
															v-model="applications.job"
															:placeholder="$t('headers.user_role')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.job"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.job"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.name_company') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.name_company
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															name="name_company"
															v-model="applications.name_company"
															:placeholder="$t('headers.name_company')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.name_company"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.name_company"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.iin') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.iin_bin
																? 'input--error'
																: '',
														]"
													>
														<TheMask
															v-model="applications.iin_bin"
															:masked="true"
															:name="'iin_bin'"
															:placeholder="$t('headers.iin')"
															:mask="['### ### ### ###']"
															type="text"
															class="input-linck"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.iin_bin"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.iin_bin"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.email') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.email
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="email"
															v-model="applications.email"
															:placeholder="$t('headers.email')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.email"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.email"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('site.registration.phone') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<div
															class="form__block--input"
															:class="[
																errorMessage.messages && errorMessage.messages.phone
																	? 'input--error'
																	: '',
															]"
														>
															<TheMask
																:value="phone"
																:placeholder="'+7 (_ _ _)  _ _ _  _ _  _ _'"
																:mask="['+7 (###) ### ## ##']"
																:masked="true"
																name="phone"
																type="tel"
																class="input-linck"
																v-on:input="phone = $event"
															/>
														</div>
														<div
															class="input-required"
															v-if="errorMessage.messages && errorMessage.messages.phone"
														>
															<p
																v-for="(massage, massageInd) in errorMessage.messages.phone"
																:key="massageInd"
															>
																{{ massage }}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div
												class="form__btn--line"
												v-if="$store.getters.userPermission(['change_user'])"
											>
												<div class="input__row">
													<button class="input-btn form__line--btn">
														{{ $t('button.save') }}
													</button>
													<a
														class="input-btn form__line--btn"
														v-if="applications.is_deleted != '0'"
														@click="activeElement(applications.id)"
													>
														{{ $t('button.activate') }}
													</a>
													<a
														class="input-btn form__line-error form__line--btn"
														v-if="applications.is_deleted != '1'"
														@click="$modal.show('modal_delete')"
													>
														{{ $t('button.deactivate') }}
														<img src="../../../assets/img/icon-close-w.svg" />
													</a>
												</div>
											</div>

											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__block--line form__messages"
														v-if="message.text"
														:class="{
															'form__messages--error': message.status != 200,
															'form__messages--success': message.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>

									<modal
										class="modal__block modal__block--middle modal__block--delete"
										name="modal_delete"
										:width="550"
										:min-width="550"
										height="auto"
									>
										<div class="modal__block--wrapper">
											<div class="modal__block--close" @click="$modal.hide('modal_delete')">
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</div>
											<div class="modal__wrapper">
												<div class="modal__header">
													<div class="modal__title">
														{{ $t('messages.are_you_sure_to_block') }}<br />
														<strong>
															{{ applications.first_name }} {{ applications.last_name }}
															{{ applications.middle_name }}
														</strong>
													</div>
												</div>
												<form method="POST" @submit.prevent="blockElement">
													<div class="modal__content">
														<div class="modal__form">
															<div class="form__line">
																<div class="form__line--right">
																	<div
																		class="form__line--input"
																		:class="[
																			errorMessage.messages && errorMessage.messages.comment
																				? 'input--error'
																				: '',
																		]"
																	>
																		<textarea
																			class="input-linck"
																			required=""
																			name="comment"
																			:placeholder="$t('labels.reject_reason')"
																		></textarea>
																	</div>
																	<div
																		class="input-required"
																		v-if="errorMessage.messages && errorMessage.messages.comment"
																	>
																		<p
																			v-for="(massage, massageInd) in errorMessage.messages.comment"
																			:key="massageInd"
																		>
																			{{ massage }}
																		</p>
																	</div>
																</div>
															</div>

															<div class="block__row">
																<div class="block__row--left">
																	<button class="block__item">
																		<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																		{{ $t('button.deactivate') }}
																	</button>
																</div>
																<div class="block__row--right">
																	<a
																		class="block__item block__item--blue"
																		@click="$modal.hide('modal_delete')"
																	>
																		{{ $t('button.cancel') }}
																	</a>
																</div>
															</div>
															<div
																class="form__block--line form__messages"
																v-if="message.text"
																:class="{
																	'form__messages--error': message.status != 200,
																	'form__messages--success': message.status == 200,
																}"
															>
																<label class="form__block--input">
																	<span class="form__block--title">
																		{{ message.text }}
																	</span>
																</label>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</modal>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { TheMask } from 'vue-the-mask'
import Multiselect from 'vue-multiselect'

import { api } from '@/boot/axios'

export default {
	name: 'UserManagementAdd',
	components: {
		TheMask,
		Multiselect,
	},
	data() {
		return {
			applications: null,
			phone: '',
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
			regionModel: [],

			roleModel: [],
			roleList: null,

			password: '',
			comparePassword: '',
			inputType: false,
		}
	},
	computed: {
		...mapGetters(['_getRegionList']),
	},
	watch: {
		applications: {
			handler() {
				this.regionModel = this._getRegionList.filter((element) => {
					return this.applications?.regions.find((region) => {
						if (element.id == region.region_id) return element
					})
				})
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions(['_fetchRegionList']),
		activeElement(id) {
			api
				.post('user/' + id + '/activate', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.$router.push({ path: '/' + this.$i18n.locale + '/account/users' })
					}
				})
		},
		blockElement(evt) {
			const formData = new FormData(evt.target)
			api
				.post('user/' + this.applications.id + '/delete', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.blockElemet = null
						this.$modal.hide('modal_delete')
						this.apiGetUser()
					}
				})
		},
		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}

			if (evt.target.iin_bin)
				evt.target.iin_bin.value = evt.target.iin_bin.value.replaceAll(' ', '')
			if (evt.target.phone) evt.target.phone.value = this.phone.replaceAll(/\D/g, '')

			const formData = new FormData(evt.target)
			const fromEntries = Object.fromEntries(formData.entries())
			if (fromEntries.password != '' && fromEntries.compare != fromEntries.password) {
				this.errorMessage.status = 422
				this.errorMessage.messages = {
					compare: [this.$t('system_message.comparePassword')],
					password: [this.$t('system_message.comparePassword')],
				}
			} else {
				formData.append('role_id', this.roleModel.id)
				if (this.regionModel) {
					this.regionModel.find((element, index) => {
						formData.append('regions[' + index + ']', element.id)
					})
				}
				if (fromEntries.password == '') {
					formData.delete('password')
					formData.delete('compare')
				}
				api
					.post('user/' + this.$route.params.id, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((response) => {
						if (response.status == 200) {
							this.message.status = 200
							this.message.text = response.data.message
							setTimeout(function () {
								this.$router.push({ path: '/' + this.$i18n.locale + '/account/users' })
							}, 3000)
						}
					})
					.catch((error) => {
						if (error?.response?.status == 500) {
							this.errorMessage.status = 500
							this.errorMessage.text = this.$t('system_message.500')
						}
						if (error?.response?.status == 401) {
							this.errorMessage.status = 401
							this.errorMessage.text = error.response.data.error_message
						}
						if (error?.response?.status == 422) {
							this.errorMessage.status = 422
							this.errorMessage.text = error.response.data.error_message

							this.message.status = 422
							this.message.text = error.response.data.error_message
						}
						this.errorMessage.messages = error.response.data.error_messages
					})
			}
		},
		apiGetUserList() {
			api
				.get('user/role/list')
				.then((response) => {
					if (response.data) {
						this.roleList = response.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
		apiGetUser() {
			api
				.get('user/' + this.$route.params.id)
				.then((response) => {
					if (response.data) {
						this.applications = response.data
						this.phone = this.applications.phone
						this.roleModel = response.data.role
						this.roleModel.accesses.find((element) => {
							if (element == 'role_region') this.roleModel.role_region = true
						})
						this._fetchRegionList().then(() => {
							this.regionModel = this._getRegionList.filter((element) => {
								return this.applications?.regions.find((region) => {
									if (element.id == region.region_id) return element
								})
							})
						})
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	async beforeMount() {
		this.apiGetUserList()
		this.apiGetUser()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped></style>
